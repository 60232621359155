import { Step, StepDescription, StepNumber, StepsSection } from "../reusable.styles"

import Layout from "../components/global/Layout"
import QuoteSection from "../components/sections/QuoteSection"
import React from "react"
import styled from 'styled-components';
import ValuePropsSection from "../components/sections/ValuePropsSection"
import TwoCol_ImageContentSection from "../components/sections/TwoCol_ImageContentSection"
import GeneralHeader from "../components/global/GeneralHeader"
import CtaSection from "../components/sections/CtaSection"

const FeedDigitalEvents = ({ data, location }) => {

  const valueProps = [
    {
      headline:"Create temporary budgets",
      subheadline:"Each attendee has a set budget and rules for ordering meals.",
      imageSrc:"https://res.cloudinary.com/grubhub-marketing/image/upload/f_auto/v1625001198/Grubhub%20Corporate%20Website/2021/Illustrations/F-DigitalEvents/EXPORTS-Illustrations-DigitalEvents-Icon1-Computer.png",
    },
    {
      headline:"Feed guests nationwide",
      subheadline:"Choose from over 300,000 restaurants in over 4,000 U.S. cities in all 50 states.",
      imageSrc:"https://res.cloudinary.com/grubhub-marketing/image/upload/v1652235127/HERMES/2022/B2B/GHCORP/B2B%20Corp%20Website/May%2011%20Handoff%20-%20NO%20HOUSE%20UPDATE/Hybrid%20Teams/EXPORT-HybridTeams-3.png",
    },
    {
      headline:"Consolidate orders to one invoice",
      subheadline:"Get a single invoice for food expenses.",
      imageSrc:"https://res.cloudinary.com/grubhub-marketing/image/upload/v1632933619/Grubhub%20Corporate%20Website/2021%20%E2%80%94%20Citrus/Illustrations/F-DigitalEvents/EXPORTS-Illustrations-DigitalEvents-Icon3-Invoice-Citrus.png",
    },
  ]

  return (
    <Layout
      location={location}
      title={"Feed Digital Events | Grubhub"}
      description={"Feed your digital events Let’s build your meal plan <!– Submit By clicking “Submit” or by using this site, you agree to Grubhub’s terms of use and privacy policy. –> Make digital events delicious Whether you’re meeting new clients, hosting a webinar or regrouping with your team, food brings everyone to the table. Create […]"}
      noindex={true}
      nofollow={true}
      utag_data={{
        pageGroup: "",
        subGroup: "",
        pageName: "",
        brand: "grubhub"
      }}
      >
      <StyledNavbarOnlyLogo>
        <NavbarContainerOnlyLogo>
          <LogoHeader src="https://res.cloudinary.com/grubhub-marketing/image/upload/v1663686777/HERMES/Logos/GH_Corporate_stacked-sRGB-OrangeGray.png" alt="Grubhub/Seamless Corporate Accounts" placeholder="blurred" width={196}/>
        </NavbarContainerOnlyLogo>
      </StyledNavbarOnlyLogo>
      <GeneralHeader
        headline="Feed Digital Events"
        preheader=""
        subheadline="Whether you're meeting new clients, hosting a webinar or regrouping with your team, food brings everyone to the table."
        textColor="var(--color-secondary)"
        background="rgba(232,226,255,0.4)"
        formID={1813}
        formEnhancedConversions={true}
        businessEmailsUsage={true}
        businessEmailUTMLink='https://www.grubhub.com/lets-eat?utm_source=corporate.grubhub.com&utm_medium=content_owned&utm_campaign=corpmarketing_digital_events_sem'
        backgroundColorForm='#FFF'
        formHeading="Let's build your meal plan"
        type="half-image"
        imageSrc="https://res.cloudinary.com/grubhub-marketing/image/upload/f_auto/v1626796712/Grubhub%20Corporate%20Website/2021/Header%20Images/Subpage%20Headers/HEADERS-SUBPAGES-2560x1707-DigitalEvents.jpg"
      />
      <ValuePropsSection
        headline="Save on the meals your virtual attendees crave"
        valueProps={valueProps}
        layout="vertical"
      />
      <TwoCol_ImageContentSection //eslint-disable-line
        imageAlignment="right"
        imageSrc="https://res.cloudinary.com/grubhub-marketing/image/upload/f_auto/v1626797840/Grubhub%20Corporate%20Website/2021/Photography%20Elements/3-Subpage-CircleImages/EXPORTS-IMAGE-Circle-DigitalEvents.png"
        headline="How virtual meetings work"
        backgroundColor="rgba(232,226,255,0.2)"
      >
        <StepsSection>
          <Step>
            <StepNumber>Step 1</StepNumber>
            <StepDescription>Create a temporary budget based on your needs</StepDescription>
          </Step>
          <Step>
            <StepNumber>Step 2</StepNumber>
            <StepDescription>Upload meal credit recipients and tell them about their credit</StepDescription>
          </Step>
          <Step>
            <StepNumber>Step 3</StepNumber>
            <StepDescription>Attendees order delivery directly to their homes</StepDescription>
          </Step>
        </StepsSection>
      </TwoCol_ImageContentSection>
      <QuoteSection
        quote='"Grubhub has increased attendance for virtual prospect and customer webinars by 120%."'
        author="Key Chong"
        position="Enterprise Account Executive at AppZen"
        imageSrc="https://res.cloudinary.com/grubhub-marketing/image/upload/v1627927171/Grubhub%20Corporate%20Website/2021/Partner%20Logos/PARTNERLOGOS-Appzen.png"
        alt="Appzen logo"
        backgroundColor="white"
      />
      <CtaSection
          headline="See what we can do for you"
          to="#general-header"
          backgroundColor="rgba(232, 226, 255, 0.2)"
      />
    </Layout>
  )
}

export default FeedDigitalEvents

const StyledNavbarOnlyLogo = styled.header`
    -webkit-box-shadow: 0px 2px 4px 0px rgba(67, 41, 163, 0.1);
    -moz-box-shadow: 0px 2px 4px 0px rgba(67, 41, 163, 0.1);
    box-shadow: 0px 2px 4px 0px rgba(67, 41, 163, 0.1);
    padding: 20px 0;
    z-index: 2000;
    position: relative;
`;

const NavbarContainerOnlyLogo = styled.div`
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
    width: 100%;
`;

const LogoHeader = styled.img`
    min-width: 200px;
    align-self: center;
`;
